.meta {
  &_assets {
    &_add-asset-btn {
      width: 100%;
      text-align: left;

      @media #{$navbreak} {
        width: fit-content;
      }
    }

    &_category-separator {
      display: block;
      border-bottom: 1px solid #dee2e6 !important;
      margin: 0 1.2rem;
    }

    &-category {
      padding-left: 0;
      padding-right: 0;
      &__title {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    &_asset {
      .badge {
        border-radius: 50%;
        width: 1.3rem;
        height: 1.3rem;
        font-size: 0.8rem;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 0 0;

        &--user {
          background-color: $navy;
          z-index: 2;
        }
        &--system {
          background-color: $navy-50;
          margin-left: -6px;
        }
      }
    }
  }

  .asset__active-label {
    width: 62px;
    height: 20px;
    background-color: $navy-50;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: $white;
    line-height: 18px;
    padding-top: 2px;
    margin-top: -2px;
    flex-wrap: wrap;

    &--active {
      background-color: $orange;
    }
  }

  .asset__usernames__item {
    &:not(:last-child) {
      &::after {
        content: ', ';
      }
    }
  }
}
