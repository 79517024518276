.settings-page {
  @media #{$md-max} {
    background-color: #f5f6fa;
  }

  .backToDash {
    display: inline-flex;
    margin-bottom: 20px;
    color: #818181;
    cursor: pointer;

    &:hover {
      color: $black;
    }

    img {
      margin-right: 10px;
      width: 17px;
    }
  }

  &__card.card {
    padding: 0;
    padding-top: 0;
    margin-bottom: 0;
  }

  .card &__section {
    padding: 20px 30px;
    border-bottom: 1px solid #eaeaea;

    &__header {
      position: relative;
      padding: 12px 30px 12px;
      border-bottom: 1px solid #eaeaea;

      h3 {
        font-size: $fs-20;
        line-height: $fs-20;
        color: $light-black;
      }

      .action-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.8rem;

        .controls {
          margin-left: 0;
        }

        .subtitle {
          line-height: pxToRem(16);
          color: $black;
        }
      }
    }

    &.compact {
      padding: 13px 30px;
    }
  }

  .card &__section:last-child {
    border-bottom: 0;
  }

  &__sectionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 0.5em;
    flex-wrap: wrap;

    @media #{$sm-max} {
      flex-direction: column;
      align-items: flex-start;
    }

    @media #{$navbreak} {
      flex-wrap: nowrap;
    }

    &.nowrap {
      flex-wrap: nowrap;

      @media #{$sm-max} {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &__sectionBody {
    display: flex;
    justify-content: space-between;
    gap: 1em;
  }

  &__sectionColumn {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  &__sectionIcon {
    width: 40px;
    height: 40px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__sectionState {
    p {
      color: #3b3b3b;
    }

    .input-cell {
      width: 100%;
      margin-left: 3px;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  &__sectionInput.form-control {
    border: 1px solid rgba(178, 178, 178, 0.5) !important;
    box-shadow: none;
    min-height: auto;
    margin: 0;
    line-height: 1;
    color: #504141;
  }

  &__sectionTitle {
    font-weight: 500;
    font-size: $fs-18;
    line-height: 21px;
    margin-bottom: 0px;
    padding-left: 3px;
  }

  &__sectionValueContainer {
    display: flex;
    align-items: center;
  }

  &__sectionValue {
    display: flex;
    border: 0px;
    padding: 3px;
    border: 1px solid #fff;
    gap: 0.3em;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
  }

  .card-settings-account__value__verified {
    display: inline-flex;
    color: #1f5274;
    font-weight: 500;
    display: flex;
    align-items: baseline;
    gap: 0.2em;
  }

  .verifield {
    height: 30px;
    width: 30px;
    border: 1px solid red;
  }

  &__sectionEdit {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 3px;

    .btn {
      border: 1px solid #d8d8d8;
      box-sizing: border-box;
      border-radius: 4px;
      padding-top: 4px;
      padding-bottom: 0px;
      width: 86px;
      color: #504141;
      font-size: $fs-16;
      line-height: pxToRem(18);
      font-weight: 500;

      &:hover {
        background-color: #1f5274;
        color: #fff;
      }
    }

    .language-switcher__button--active {
      background-color: #1f5274;
      color: #fff;
    }
  }

  &__sectionDropdown {
    width: 172px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__sectionEdit--doubleButton {
    .btn:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-right: -1px;
    }

    .btn:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  &__logout {
    color: #f58562;
    font-weight: 500;

    &:hover {
      color: #443f3f;
    }
  }

  @media #{$md-max} {
    .settings-page__container {
      .settings-page__card {
        margin-bottom: 0;
      }

      .settings-page__section:last-child {
        border-bottom: 0;
      }
    }

    .container-fluid {
      padding-right: 0;
      padding-left: 0;
      overflow: hidden;
    }

    .card .settings-page__section {
      padding: 20px;

      &__header {
        padding: 12px 20px 12px;
      }
    }

    .card.card-logout {
      .settings-page__section {
        border-bottom: 0;

        button {
          font-weight: 500;
          font-size: $fs-16;
          line-height: pxToRem(18);
        }
      }
    }

    .settings-page__sectionTitle {
      font-size: $fs-20;
      line-height: pxToRem(23);
    }
  }

  .tabs-component {
    padding: 0;
    margin-top: 2rem;
    &_pills {
      &_wrapper {
        background-color: $white;
        border-radius: $card-border-radius;
        border-bottom: none;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: start;
        gap: 2.4rem;
        font-size: pxToRem(20);
        margin-bottom: 10px;
        overflow: auto;
        // hide scrollbar
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }

        @media #{$navbreak} {
          gap: 4rem;
        }

        @media #{$md} {
          padding-left: 30px;
          padding-right: 30px;
        }
      }

      &_each {
        padding-top: 12px;
        white-space: nowrap;
        padding-left: 0;

        // for inactive tabs (missing active class, use the selector: .tabs-component_pills_each:not(.active))
        &:not(.active) {
          font-weight: 600;
          color: lighten($txt-content-2, 10%);
        }
      }
    }
  }
}

.setting-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: -20px;

  .custom-check__label {
    color: $black;
  }

  .custom-check__input,
  .custom-check__box {
    position: relative !important;
  }
}
