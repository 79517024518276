@mixin facebook_icon {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  height: pxToRem(42);
  width: pxToRem(42);
  border-radius: 50%;
  margin-right: pxToRem(16);
  position: relative;
  background-color: #f7f8fa;
  color: $navy-75;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-events {
    font-size: $fs-14;
  }
}

@mixin facebook_profile_picture {
  height: pxToRem(42);
  width: pxToRem(42);
  border-radius: 50%;
  margin-right: pxToRem(16);
}

@mixin facebook_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;

  .subtitle {
    color: #9c9da0;
    font-size: pxToRem(13);

    svg {
      path {
        fill: #9c9da0;
      }
    }
  }
}

@mixin facebook_link_media {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@mixin facebook_link_order_now {
  background-color: #f7f8fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  padding: 1em;
  color: black;
  width: 100%;
  border-bottom: 1px solid #c9ccd1;

  .button {
    text-align: center;
    background-color: #e4e6eb;
    color: black;
    font-weight: bold;
    border-radius: 6px;
    font-size: pxToRem(15);
    padding: 0.5rem 1rem;
    white-space: nowrap;
  }

  .text {
    overflow-wrap: anywhere;
    display: flex;
    gap: 0.25em;

    .url {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgb(101, 103, 107);
      font-size: pxToRem(13);
    }

    .headline {
      font-size: pxToRem(16);
      font-weight: 600;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@mixin facebook_interactions {
  display: flex;
  padding-top: 10px;

  svg {
    width: 18px;
    margin-right: 0.5em;

    path {
      fill: #5e6771;
    }
  }

  button {
    padding: 0em 0.7em 0.7em 0.7em;
    width: 50%;
    color: #5e6771;
    cursor: default;
    font-size: pxToRem(15);
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.accordion-arrow {
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid $arrow;
  transition-duration: 0.2s;
  margin-left: auto;
  transform: rotate(0);

  &.open {
    transform: rotate(180deg);
  }
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.fb-error {
  background: #f7f8fa;
  border-radius: $card-border-radius;
  padding: 0.75em 1em;
  color: $txt-header-2;
  flex-grow: 1;
  height: 100%;
}

.loading {
  background: linear-gradient(to right, #e8eaed, #f7f8fa, #e8eaed);
  background-position: left;
  background-size: 200%;
  animation: skeleton-loading 1s ease-in-out infinite normal;
}

@keyframes skeleton-loading {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

.suggestions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow: auto;
}

.suggestion-card-header {
  color: #544141;
  margin-bottom: pxToRem(5);
}

.suggestion-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  font-size: pxToRem(14);
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    '.SFNSText-Regular',
    sans-serif;
  border: 2px solid #c1c5d3;
  border-radius: $card-border-radius;
  width: fit-content;

  .ad-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    & > span {
      font-size: $fs-20;
      display: none;
      padding: 1rem;
      text-align: center;
    }

    &:hover,
    &.disabled {
      background: #fffa;
      & > span {
        display: block;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  p {
    text-align: start;

    strong {
      font-size: pxToRem(15);
      font-weight: 600;
      line-height: 1.333;
    }
  }

  @media #{$xs-only} {
    width: pxToRem(330);
    overflow-x: auto;
  }

  &__web {
    width: pxToRem(540);
  }

  &__mobile {
    width: pxToRem(340);

    @media #{$xs-only} {
      width: auto;
    }
  }
}

.facebook {
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: white;
  border-radius: $card-border-radius;

  p,
  strong {
    color: black;
  }

  .profile_picture {
    @include facebook_profile_picture;
  }

  .icon {
    @include facebook_icon;
  }

  .title {
    @include facebook_title;
  }

  .link {
    &.tiny-media {
      display: flex;

      .media {
        @include facebook_link_media();
        width: 25%;
      }
    }

    .media {
      @include facebook_link_media();
    }

    .order-now {
      @include facebook_link_order_now();
    }
  }

  .interactions {
    @include facebook_interactions();
  }

  .divider {
    height: 1px;
    background-color: #c9ccd1;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
  }

  &.mobile {
    p {
      .text {
        &::after {
          content: '\00a0';
        }

        &_clipped {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          text-align: left;
        }
      }

      .more {
        cursor: pointer;
        color: #9c9da0;
      }
    }

    .title {
      @include facebook_title();
      font-size: 18px;
    }

    .actions {
      svg {
        path {
          fill: #000;
          stroke: #000;
        }
      }
    }

    .link {
      &.tiny-media {
        display: flex;

        .media {
          @include facebook_link_media();
          width: 35%;
        }

        .order-now {
          @include facebook_link_order_now();
          flex-direction: column;
          align-items: flex-start;

          .button {
            background-color: #1b74e4;
            color: white;
          }
        }
      }

      .order-now {
        @include facebook_link_order_now();

        .button {
          background-color: #1b74e4;
          color: white;
        }
      }
    }

    .interactions {
      @include facebook_interactions();
      font-size: pxToRem(14);

      svg {
        width: 15px;
      }
    }
  }
}

.instagram {
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: $card-border-radius;

  p {
    color: black;
  }

  svg {
    path {
      fill: black;
    }
  }

  &__profile_picture {
    @include facebook_profile_picture;
  }

  &__icon {
    @include facebook_icon();
  }

  &__title {
    @include facebook_title();

    strong {
      color: black !important;
    }
  }

  &__link {
    &_media {
      @include facebook_link_media();
    }

    &_order-now {
      font-size: pxToRem(14);
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      gap: 2em;
      padding: pxToRem(16);

      svg {
        width: 15px;

        path {
          fill: #000;
        }
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #c9ccd1;
    margin-right: 10px;
    margin-left: 10px;
  }

  &__interactions {
    display: flex;
    justify-content: space-between;
    padding: pxToRem(14) pxToRem(16) 0px pxToRem(16);

    span {
      margin-right: 1em;
    }

    svg {
      width: 21px;
      height: 18px;

      path {
        fill: #6d6d6d;
      }
    }
  }

  &__caption {
    display: inline-block;
    padding: 0px pxToRem(16);

    .text {
      &::after {
        content: '\00a0';
      }

      &_clipped {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        text-align: left;
      }
    }

    .more {
      cursor: pointer;
      color: #9c9da0;
    }
  }
}

.reel {
  display: flex;
  flex-direction: column;
  gap: 1em;
  aspect-ratio: 0.55;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  border-radius: $card-border-radius;
  position: relative;

  p,
  h2,
  strong,
  span {
    color: white !important;
  }

  svg {
    path {
      fill: white;
    }
  }

  &__profile_picture {
    @include facebook_profile_picture;
    margin-right: 0.5rem;
  }

  &__background {
    background-position: center;
    background-size: cover;
    border: none;
    border-radius: $card-border-radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__main-title {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem;
    background: linear-gradient(rgb(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    position: relative;
    z-index: 1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    position: relative;
    z-index: 1;
  }

  &__icon {
    @include facebook_icon();
    margin-right: 0.5rem;
  }

  &__title {
    @include facebook_title();
    font-size: pxToRem(13);
    opacity: 0.9;

    .subtitle {
      opacity: 0.8;
      font-size: pxToRem(13);
    }
  }

  &__order-now {
    background-color: white;
    color: black;
    padding: pxToRem(12) pxToRem(8) pxToRem(12) pxToRem(15);
    border-radius: 6px;
    font-size: pxToRem(13);
    width: 100%;
    cursor: default !important;
    display: flex;
    justify-content: center;
    gap: 2em;
  }

  &__interactions {
    display: flex;
    justify-content: flex-end;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: white;
      }
    }
  }

  &__caption {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    font-size: pxToRem(12);

    .text {
      &::after {
        content: '\00a0';
      }

      &_clipped {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        text-align: left;
      }
    }

    .more {
      cursor: pointer;
      color: #929598 !important;
    }
  }
}

.fadeout {
  animation: fadeout 1s ease-out;
}

@keyframes fadeout {
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
